//компонент-функция//
import React, {useState} from 'react'
import { FaShoppingCart } from "react-icons/fa"
import Order from './Order'


//Если в корзине есть товары://
const shovOrders = (props) =>{/*"это наша функция показа содержимого корзины*/
                              /*props здесь это переменная переданная сюда при вызове этой функции ниже: массив с товарами находящимися в корзине*/
   let summa = 0/*переменная с суммой стоимости всех товаров в корзине*/
   //калькулируем суммы в корзине://
   //1)прогоняем массив orders через цикл forEach
   //2)каждый итый (price * колич) цикла прибавляем к переменной summa, но сначала цену из текстового формата переводим в числовой://
   //для этого обрыщяемся к методу JS по работе с числами (Number). А затем вызываем из него метод parseFloat преобразующий текст в число.//
   props.orders.forEach(el => summa += Number.parseFloat(el.price * el.countGoods))
   return (<div>
            {/*товары в корзине:*/}
            {props.orders.map(el => (
               <Order key={el.id} item={el} countGoodsOrder={props.countGoodsOrder} onDelete={props.onDelete}/>
            ))}
               {/**1) подключаем сюда многократно по циклу map, компонент Order,*/}
               {/*/*при этом при каждой итерации передаем очередной-i объект массива (el из цикла map(el)) т.е. товары в корзине, */}
               {/*/*а также что бы не вылезала ошибка, ключ объкта массива т.е. id: key={el.id}*/}
               {/*//2) еще пробрасываем дальше из App в Orders нашу функцию: (onDelete=deleteOrder(id))/*/}
            {/*Сумма стоимости всех товаров в корзине: вывод переменной summa*/}
            {/*мантра {new Intl.NumberFormat().format(summa)} здесь нужна для того чтобы округлить значения переменной summa до двух знаков, после запятой т.к. JS иногда глючит при сложении дробей и может вадавать, например: 10,99000000002 руб*/}
            {/*<p className='summa'>Сумма: {summa}$</p>*/}
            <p className='summa'>Sum: {new Intl.NumberFormat().format(summa)}$</p> {/*{summa}: вывод переменной summa*/}
            <p className='pay' onClick={() => props.onPay()}>Checkout</p>
            <br/>
         </div>) 

}

//Если в корзине нет товаров вызывается эта функция//
const showNothing = () => {
   return (<div className='empty'>
               <h2>There are no products</h2>
            </div>)
   }

export default function Header(props) { //props: так как Header это копанент-функция, а не компонент класс, в него передаются переменные как в обычную функцию Header(props)//
                                       //в данном случае в него в (props), App передает массив с товарами добавленными в корзину (oders[])//
   let [cartOpen, setCartOpen] = useState(false)/*хук для корзины с булеевым значением: false или true*/
   let count = 0/*переменная сколичеством товара в корзине*/
   props.orders.forEach(el => count += el.countGoods)

   return (
   <header>
         <span className='logo' onClick={() => props.StateUpdate()}>House Portier</span>
         <br/><br/>
         <ul className='nav'>
            <li>About Us</li>
            <li>Contacts</li>
            <li onClick={() => props.onShowLogin()}>{!props.userData.name ? 'Login' : 'Exit'}</li>
         </ul>        
       {/*ярлык корзины*/}
       <div className='basket'>
            <FaShoppingCart onClick={() => setCartOpen(cartOpen = !cartOpen)} className={`shop-cart-button ${cartOpen && 'active'}`}/>
                  {/*перевод: 1) FaShoppingCart: выводим иконку из библиотеки иконок*/}
                  {/*2) При нажатии на икону меняем значение булеевого хука cartOpen на противоположное(cartOpen = !cartOpen) т.е. false на true и наоборот т.е. тумблер*/}
                  {/*3) Меняем className блока. Так если иконка была нажата первый раз то хук cartOpen = true добавляем к класу shop-cart-button префикс 'active'*/}
                  {/*пункт 3 реализован через условие IF: ${cartOpen && 'active'}, где $ указывает на то что это не HTML, а JS код.*/}
                  {/*условие IF: ${cartOpen && 'active'} - Означает: если хук cartOpen = true ТО добавить 'active'.  &&-означает что условие используется без else*/}
            {count>0 && <div className='count'>{count>0 && count}</div>} {/*количество товаров в корзине*/}
         </div>
         {/*Содержание корзины:*/}
         {cartOpen && (/*если жмякнули по иконке корзина т.е. если хук cartOpen = true то вывети на экран блок  className='shop-cart'*/
            //Корзина (содержание)//
            <div className='shop-cart'>
               {/*Условие IF: если список товаров в корзине длинее 0:*/}
               {/*то запускаем нашу функцию расположеную выше (вывод на экран содержимого корзины shovOrders(props)) плюс передаем в нее массив товаров корзины (props)*/}
               {/*Иначе запускаем функцию showNothing(), которая выведет на экран: <h2>Toeapoв нет </h2>*/}
               {props.orders.length > 0 ?
               shovOrders(props) : showNothing()}
            </div>
         )}
      <br/><br/>
      <h5 id='infor'>ATTENTION!</h5>
      <h5 id='infor'>The site works in demo mode, all actions on it are GAME!</h5>
      <div className='presentation'></div>{/*основной банер нашего магазина*/}
   </header>
  )
}
