import React, { Component } from 'react'
import { TbShoppingCartCheck } from "react-icons/tb"
import { FaRegBell } from "react-icons/fa" //звонок


export class ShowFullItem extends Component {
  render() {
    return (
      <div className='full-item'>
         <div>{/*фон}*/}
            <img src={"./img/" + this.props.item.img} onClick={() => this.props.onShowItem(this.props.item)} />
                                                      {/*при нажатии на изображение onClick вызовет безымянную функци ({() => ...}), которая в свою очередь...*/}
                                                      {/*вызовет функцию переданную сюда из App (onShowItem(с товаром: this.props.item), выводящую окно с полным описанием товара*/}
            <h2 onClick={() => this.props.onShowItem(this.props.item)}>{this.props.item.title}</h2>
            <p onClick={() => this.props.onShowItem(this.props.item)} >{this.props.item.desc}</p>
            {/*цена с условием:*/}
            {this.props.item.balance>0 && <b>{this.props.item.price}$</b>}
         {/*сделать предзаказ с условием:*/}
         {this.props.item.balance<=0 && <div>
                                          <div className='balanceNothingInfo'>Товар временно отсутствует</div>
                                          <div className='booking' onClick={() => this.props.onShowBooking(this.props.item)}>Оставить заявку <FaRegBell className='Bell' /></div>
                                       </div>}
            {/*кнопка добавить в корзину с условием:*/}
            {this.props.item.balance>0 && <div className='add-to-cart' onClick={() => this.props.onAdd(this.props.item)}>+</div>}
                                          {/*При нажатии этой кнопки (onClick) запускается безимянная функция ({() => })*/}
                                          {/*которая вызывает функцию добавления товаров в корзину addToOrder(item) находящуюся в App*/}
                                          {/*и переданую сюда (this.props.onAdd) в переменной onAdd */}
                                          {/*при этом передаем в эту функцию текущий товар этой  нажатой кнопки: onAdd(this.props.item)*/}
                                                   {/*ярлык добавлено в корзину:*/}
         {this.props.orders.map(el => (this.props.item.id === el.id && <TbShoppingCartCheck className='shopping_cart' key={el.id}/>))}
         {/*key={el.id} нужен чтобы не ругался React*/}

         </div>
      </div>
    )
  }
}

export default ShowFullItem